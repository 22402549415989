.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 130px;
  box-sizing: border-box;
  background-color: rgba(239, 239, 239, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  margin-bottom: 3em;
  margin-top: 1.5em;
}

.contact-title {
  color: grey;
}

.background-container {
  position: relative; 
  width: '100%'; 
  height: 70vh; 
  overflow: hidden; 
}

.background-container > div {
  position: absolute; 
  width: 100%;
  height: 100%;
}


.form-label {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.form-input,
.form-textarea {
  margin-bottom: 20px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.form-textarea {
  min-height: 150px;
  resize: vertical;
}


.form-submit {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.form-submit:hover {
  background-color: #2980b9;
}


button {
  border: none;
  outline: none;
  background-color: #ff5722;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-radius: 5px;
  transition: all ease 0.1s;
  margin-top: 1.3em;
}

button:active {
  transform: translateY(5px);
  box-shadow: 0px 0px 0px 0px #a29bfe;
}

.profile-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.button-link {
  display: inline-block;
  margin: 10px;
  padding: 6px 10px;
  background-color: #ff5722; 
  color: white; 
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.button-link:hover {
  background-color: #c6502dca;
  color: white;
}


@media (max-width: 600px) {
  .contact-form {
    padding: 10px;
  }

  .form-label {
    font-size: 16px;
  }

  .form-input,
  .form-textarea {
    font-size: 14px;
  }
}
