@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap');

$font-stack: "Poppins", sans-serif;

* {
  margin: 0;
  font-family: 'Bruno Ace SC', cursive;
}

.all {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.top {
  margin-bottom: -53px;
  margin-left: 23px;
  background-color: transparent;
  border: 2px solid white;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  transform: rotateY(0deg) rotate(45deg);
}

.inner-oval {
  align-items: center;
  border: 2px solid white;
  width: 150px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
}

.circle1 {
  border: 2px solid white;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  animation: zoom 3s linear 0s infinite;
}

.circle2 {
  border: 2px solid white;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  animation: zoom 3s linear 0s infinite;
  animation-delay: 1s;
}

.circle3 {
  border: 2px solid white;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  animation: zoom 3s linear 0s infinite;
  animation-delay: 2s;
}

@keyframes zoom {
  0% {
    backround-color: black;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: black;
  }
}

.projects-h1 {
  text-align: center;
  margin-top: -4rem;
  margin-bottom: 5rem;
  color: grey;
}

.slide-container {
  width: 70%;
  margin: 0 auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.slide-image-container {
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: 420px;
}

.slide-caption {
  padding: 15px;
  background: '#C5C5C5';
  color: rgba(239, 239, 239, 0.8);
  width: 100%;
  text-align: center;
}

.project-container {
  background-color: rgba(239, 239, 239, 0.8);
  padding: 130px;
  color: var(--text-color);
  background-color: var(--background-color);
  
  a {
    color: var(--link-color);
  }
}

.project-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: rgba(239, 239, 239, 0.8);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  }
  
  .project-button:hover {
  background-color: rgba(163, 161, 161, 0.827);
  }
  
  .slide-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  }
  
  .buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  background-color: '#C5C5C5';
  }
  
  a.project-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: rgba(239, 239, 239, 0.8);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  }
  
  a.project-button:hover {
  background-color: #5a5a5a;
  }

  .react-slideshow-container .default-nav {
    height: 40px !important;
    background: #ff5622cf !important;
    width: 30px; 
    border: 0;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
  @media only screen and (max-width: 768px) {
  .slide-container {
  width: 90%;
  }
  
  .slide-image-container {
  height: 300px;
  }
  
  .project-container {
  padding: 20px;
  }
  
  .inner-oval {
  width: 120px;
  height: 20px;
  }
  
  .top {
  width: 80px;
  height: 80px;
  }
  
  .projects-h1 {
  font-size: 24px;
  margin-top: 5%;
  }
  
  .slide-caption, .project-button, a.project-button {
  font-size: 14px;
  }
  }
  
  @media only screen and (max-width: 480px) {
    .container {
      align-items: center;
    }
  
    .top {
      margin-bottom: -40px;
      margin-left: 15px;
    }
  
    .inner-oval {
      justify-content: space-between;
    }
  
    .circle1, .circle2, .circle3 {
      width: 8px;
      height: 8px;
    }
  
    .projects-h1 {
      margin-top: -3rem;
      margin-bottom: 3rem;
    }
  
    .slide-container {
      box-shadow: none;
    }
  
    .project-container {
      padding: 80px 20px;
    }
  
    .project-button, a.project-button {
      padding: 8px 16px;
    }
  
    .buttons-container {
      flex-direction: column;
    }
  }
  
  .someComponent {
    color: var(--text-color);
    background-color: var(--background-color);
  
    a {
      color: var(--link-color);
    }
  }
  