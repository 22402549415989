
.App {
  /* Define default (light) theme properties */
  --text-color: 'grey';
  --background-color: #ffffff;
  --link-color: #0000ff;
  color: var(--text-color);
  background-color: var(--background-color);
  
  a {
    color: var(--link-color);
  }
}

.App.light {
  --text-color: 'grey';
  --background-color: #ffffff;
  --link-color: #ffffff;
}

.App.dark {
  --text-color: #ffffff;
  --background-color: #000000;
  --link-color: #ffffff;
}
