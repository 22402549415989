@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@700&display=swap');


.about {
  color: grey;
  width: 90%; 
  max-width: 800px;
  margin: 20px auto; 
  font-size: 30px;
  padding-bottom: 6em;
  text-align: center;
  font-family: 'Kanit', sans-serif;
}

.tech {
  margin-top: -2em;
}

.about-container {
  padding: 3em; 
}

.about-me {
  color: grey;
}

.logoskills {
  height: 60px;
  width: 60px;
  margin: 0 5px;
  margin-top: 1.5em;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-row {
  display: flex;
  justify-content: center;
}


@media only screen and (max-width: 600px) {
  .about {
    width: 100%;
  }

  .tech {
    margin-top: 1em;
  }

  .about-container {
    padding: 1em;
  }

  .image-row {
    flex-wrap: wrap;
  }
}

