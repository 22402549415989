.header-nav {
  width: 100%;
  background: #fff;
  
  label, #hamburger {
    display: none;
  }

  color: var(--text-color);
  background-color: var(--background-color);
  
  a {
    color: var(--link-color);
  }

  #nav-items {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid #e0e0e0;
    padding: 16px;
    width: 90%;
    margin: 0 auto;

    a {
      flex-grow: 1;
      flex-basis: 0;
      text-decoration: none;
      color: #555555;
      transition: color 0.3s ease;

      &:hover {
        color: #9b9494;
      }

      div {
        padding: 8px;
        font-weight: 500;
        transition: background-color 0.3s ease;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      }
    }
  }

  @media only screen and (max-width: 600px) {


    #nav-items {
      display: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      a {
        display: block;
        margin: 10px 0;
        width: 100%;
        border-top: 1px solid #ff5722;
      }
    }

    label {
      display: inline-block; 
      color: #ff5722;
      background: #fff;
      font-style: normal;
      font-size: 2.3em;
      padding: 10px;
      color: var(--text-color);
      background-color: var(--background-color);
      
      a {
        color: var(--link-color);
      }
    }

    #hamburger:checked ~ #nav-items {
      display: block;
    }
  }
}
