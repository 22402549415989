h4 {
  display: flex;
  justify-content: center;
  color: white;
}

h1 {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 36px;
}

h2 {
  display: flex;
  justify-content: center;
  color: grey;
  font-size: 36px;
}

h3 {
  display: flex;
  justify-content: center;
  color: grey;
  font-size: 36px;
}

span::before {
  color: #ff5722;
  content: "Software Engineer";
  animation: animate infinite 3s;
  padding-left: 10px;
}

@keyframes animate {

  70% {
      color: #ff5722;
      content: "Fullstack Developer";
  }
}

.heading {
  color: grey;
}

.main-heading {
  margin-top: 8em;
  left: 50%;
  flex: 1;
}

.section-1 {
  padding-bottom: 30em;
  display: flex;
}

.image-container img {
  width: 275px;
  height: 275px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ff5722;
  filter: drop-shadow(0 0 8px #ff5722);
  margin-top: 10%;
}

.image-container {
  position: absolute; 
  top: 70%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex-shrink: 0; 
}

@media only screen and (max-width: 600px) {
  h1, h2, h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  .main-heading {
    margin-top: 4em;
    margin-left: 2em;
  }

  .image-container {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    margin-top: 2em;
  }

  .section-1 {
    flex-direction: column;
    padding-bottom: 10em;
  }
}

.main-heading h1 {
  color: grey;
}

.background-container {
  position: relative;
}

.section-1 {
  padding-bottom: 5em;
}

.dark-light-button {
  position:absolute;
  top: 1%;
  right: 4.1%;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .dark-light-button {
    top: 1%;  
    margin-right: 25%;
    z-index: 1;
  }
}

